<template>
	<div style="text-align: center;background-color: #EBEEF5;padding-bottom: 20px;">
		<div  class="sameWidth">
			<el-breadcrumb separator-class="el-icon-arrow-right" style="padding:20px 0px;">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/news' }">新闻中心</el-breadcrumb-item>
					<el-breadcrumb-item>文章详情</el-breadcrumb-item>
				</el-breadcrumb>
			<div style="text-align: left;padding:60px;margin:10px;background-color: #fff;">
				<div style="">
					<div id="tenderDiv">
						<h3 style="text-align: center;">{{info.news_name}}</h3>
						<h4 style="text-align: center;color:#909399;"><span style="margin-right: 50px;">{{info.release_time}}</span><span></span></h4>
					</div>
					<div v-html="info.news_content" id="wangTable">
					</div>
				</div>
			</div>
		</div>
			
	</div>
</template>
<style>
	#wangTable img{max-width: 100%;}
	#wangTable table{
		width:100%;
		border-top:#DCDFE6 1px solid;
		border-left:#DCDFE6 1px solid;
	}
	#wangTable table td,#wangTable table th{
		border-bottom:#DCDFE6 1px solid;
		border-right:#DCDFE6 1px solid;
		padding: 10px;
		height: 25px;
		line-height: 25px;
	}
</style>
<script>
export default {
  data() {
    return {
		info:'',
		screenWidth:1300,
    };
  },
  mounted() {
	this.bus.$emit('headerImg',[{src:require('@/assets/images/bk/新闻.jpg'),href:""}])
	var that=this;
	this.$axios.post(this.baseUrl+'admin/article/searchArticle?newsId='+this.$route.query.id).then(response=>{
		if(response.data.code!='200'){
			that.$alert(response.data.msg, '提示', {confirmButtonText: '确定'});
		}else{
			that.info=response.data.data.res[0];
			console.log(response);
		}
	}).catch(function (error) {window.console.log(error)});//ajax请求失败
	this.screenWidth=document.documentElement.clientWidth;
	window.addEventListener('resize',()=>{
		this.screenWidth=document.documentElement.clientWidth;
	})
  }
};
</script>